<template>
  <div class="mt-2">
    <el-row>
      {{ title }}
      <el-col :span="24">
        <div class="form-group">
          <el-input type="textarea" v-model="field.description" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["field", "title"],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>